'use client';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.background.lightest};
  border-radius: ${props => props.theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Content = styled.div`
  max-width: none;
  margin: ${props => props.theme.spacing(4)};

  @media ${({ theme }) => theme.tablet} {
    width: 100%;
    max-width: 320px;
    margin: ${({ theme }) => theme.spacing(0)};
  }
  @media ${({ theme }) => theme.laptop} {
    max-width: 350px;
    margin: ${({ theme }) => theme.spacing(4, 0, 4, 8)};
  }
`;

export const Title = styled.p`
  width: 320px;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.29px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.tablet} {
    text-align: left;
    font-size: 28px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacing(2)};

  @media ${({ theme }) => theme.tablet} {
    justify-content: start;
  }
`;

export const DesktopImageContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.tablet} {
    position: relative;
    display: flex;
    align-content: flex-end;
    max-width: 275px;
    width: 100%;
    background-color: #b2d1ff;
    border-radius: ${props => props.theme.spacing(8, 0, 0, 8)};
  }

  @media ${props => props.theme.laptop} {
    max-width: 345px;
  }
`;
export const MobileImageContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  align-content: flex-end;
  background-color: #b2d1ff;
  border-radius: ${props => props.theme.spacing(8, 8, 0, 0)};

  @media ${({ theme }) => theme.tablet} {
    display: none;
  }
`;

export const AppStoreAnchor = styled.a``;

export const QRSectionContainer = styled.div`
  display: none;

  @media ${props => props.theme.tablet} {
    display: flex;
    flex-direction: row;
    margin-top: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(5)};
  }
  @media ${props => props.theme.tabletL} {
    padding-right: ${props => props.theme.spacing(10)};
  }
`;

export const QRImageContainer = styled.div`
  min-width: 85px;
  @media ${props => props.theme.laptop} {
    width: 100%;
  }
`;

export const QRExplanationContainer = styled.div`
  display: none;

  @media ${props => props.theme.tablet} {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 60px;
    padding-left: ${props => props.theme.spacing(2)};
  }
  @media ${props => props.theme.laptop} {
    max-width: none;
    padding-left: ${props => props.theme.spacing(0)};
  }
`;

export const StyledQRExplanation = styled.p`
  ${props => props.theme.typography.bodyBold}
  line-height: normal;
  letter-spacing: 0.16px;
  text-edge: cap;
  leading-trim: both;
`;

export const CTASectionContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
  margin: ${props => props.theme.spacing(2, 0, 4, 0)};

  @media ${props => props.theme.tablet} {
    display: none;
  }
`;
