'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import { costFormatter } from '@format/currency';
import { LoadingView } from '@web/components';
import { PlanSummary } from '../PlanSummary';
import {
  usePets,
  formToModel,
  getAllergies,
  getAgeAdjective,
  getBreed,
} from '../../pets';
import {
  useAddress,
  useSessionCustomer,
  useCartLineItemForPet,
} from '../../customer';
import { RedirectToLogin } from '../../router';
import {
  usePaymentIntent,
  getPendingIntentInternalId,
  usePayment,
} from '../../payment';
import { useReporter } from '../../reporter';
import { useToast } from '../../Toast';
import { useAddPupCart } from '../../addPup';
import { ApiResult } from '../../api';
import {
  PAYMENT_ERROR_MESSAGE,
  PAYMENT_ERROR_MESSAGE_INSUFFICIENT_FUNDS,
} from '../constants';
import { useProduct } from '../../products';

export const AddPupPlanSummaryContainer = ({
  headline,
  subheadingText,
  confirmationText,
  cancelButtonLabel,
  boxheadline,
  period,
  pupInfoTarget,
  mealPrepTarget,
  freshRecipesTarget,
  bakedRecipesTarget,
  paymentTarget,
  deliveryTarget,
  cancelTarget,
  productSku,
  subscriptionSku,
  starterBox,
  weeksOfFood,
  petForm,
  onSubmit,
  onCancel,
  cartId = '',
  isLegacyCustomer = true,
  checkoutButtonText,
  confirmationButtonText,
}) => {
  const { createPet, deletePet } = usePets();
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
    performCheckout,
  } = useSessionCustomer();
  const { payment, isLoading: isPaymentLoading } = usePayment(customer?.id);
  const { address, isLoading: isAddressLoading } = useAddress(customer?.id);
  const { addMealPlanToCart } = useCartLineItemForPet();
  const reporter = useReporter();
  const { showToast } = useToast();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { product, isLoading: isProductLoading } = useProduct(subscriptionSku);

  const pet = formToModel(petForm);
  const { createIntent, confirmAsyncTransaction } = usePaymentIntent();

  const { data: addPupCart, isLoading: isAddPupCartLoading } = useAddPupCart(
    cartId && !isLegacyCustomer ? cartId : null,
  );

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (
    isProductLoading ||
    isCustomerLoading ||
    isAddressLoading ||
    isPaymentLoading ||
    isAddPupCartLoading
  ) {
    return <LoadingView />;
  }

  const totalAmount = costFormatter(product.variants[0].price_per_week);

  const discountedAmountValue = costFormatter(
    product.variants[0].discounted_price_per_week,
  );

  const discountedPeriodAmount =
    discountedAmountValue !== totalAmount ? discountedAmountValue : null;

  let paymentInfo = [];
  if (payment) {
    paymentInfo = [
      `${payment.brand}`,
      `Ending in ****${payment.last_four_digits}`,
    ];
  }

  const allergyPrefix = {
    none: 'with no allergies',
    one: 'with an allergy to ',
    many: 'with allergies to ',
  };
  const petAge = getAgeAdjective(pet.birth_date);
  const petAllergies = getAllergies(
    petForm.allergies.map(allergy => ({ name: allergy.label })),
    allergyPrefix,
  );
  const petNameAndAge = `${pet.name} is a ${petAge}`;
  const petBreed = getBreed(
    petForm.breeds.map(breed => ({ name: breed.label })),
  );
  const petWeightAndBreed = [`${pet.weight}-lb`, petBreed].join(' ');
  const pupsInfoString = [petNameAndAge, petWeightAndBreed, petAllergies].join(
    ', ',
  );
  const pupsInfo = [pupsInfoString];

  const deliveryInfo = [
    address.address1,
    address.address2,
    `${address.city}, ${address.state} ${address.zip_code}`,
  ].filter(Boolean);

  const totalPacks = Number(starterBox?.tray_count);
  const totalBags = Number(starterBox?.bag_count) || 0;

  const interpolatedHeadline = interpolate(headline, {
    pupName: petForm.name,
  });

  const onConfirm = async () => {
    setIsSubmitting(true);
    const petResult = await ApiResult.callAsync(() =>
      createPet(formToModel(petForm)),
    );

    const cartResult = await ApiResult.flatMapAsync(petResult, response =>
      addMealPlanToCart({
        product_sku: productSku,
        subscription_sku: subscriptionSku,
        pet_id: response.data.id,
      }),
    );

    const paymentIntentsResponse = await createIntent(
      cartResult.payload.data.cart_id,
      payment.id,
    );

    const paymentIntents = paymentIntentsResponse?.payload;
    const pendingIntentId = getPendingIntentInternalId(
      paymentIntentsResponse?.payload,
    );
    const publicIds = paymentIntents?.map(
      ({ transaction_id }) => transaction_id,
    );
    const confirmAsyncTransactionResult = await ApiResult.flatMapAsync(
      paymentIntentsResponse,
      () => confirmAsyncTransaction(pendingIntentId, payment.id),
    );

    const checkoutResult = await ApiResult.flatMapAsync(
      confirmAsyncTransactionResult,
      () => performCheckout(publicIds),
    );

    ApiResult.match(checkoutResult, {
      success: () => {
        onSubmit(petResult.payload.data.id);
      },
      error: {
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message:
              'Hmm. Something went wrong. Please refresh the page and try again and ensure your Credit Card and Shipping Information are up to date.',
            headline: 'Error Message',
          });
        },
        server: () => {
          showToast({
            status: 'error',
            message:
              "We weren't able to complete your request, please refresh the page and try again.",
            headline: 'Error Message',
          });
        },
        paymentInvalid: () => setErrorMessage(PAYMENT_ERROR_MESSAGE),
        paymentInsufficientFunds: () =>
          setErrorMessage(PAYMENT_ERROR_MESSAGE_INSUFFICIENT_FUNDS),
      },
    });
    ApiResult.ifError(checkoutResult, () => {
      setTimeout(() => setIsSubmitting(false));
      if (petResult.payload?.data.id) deletePet(petResult.payload.data.id);
    });
  };

  return (
    <PlanSummary
      name={customer.first_name}
      headline={interpolatedHeadline}
      subheadingText={subheadingText}
      pupsInfo={pupsInfo}
      recipesInfo={product.variants[0].recipes}
      portionSize={product.portion_size}
      paymentInfo={paymentInfo}
      paymentMethodIsActive={payment?.is_active}
      deliveryInfo={deliveryInfo}
      confirmationText={confirmationText}
      cancelButtonLabel={cancelButtonLabel}
      boxheadline={boxheadline}
      offerCategory={null}
      discountedPeriodAmount={discountedPeriodAmount}
      totalAmount={totalAmount}
      totalPacks={totalPacks}
      totalBags={totalBags}
      period={period}
      errorMessage={errorMessage}
      onSubmit={onConfirm}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
      pupInfoTarget={pupInfoTarget}
      mealPrepTarget={mealPrepTarget}
      freshRecipesTarget={freshRecipesTarget}
      dryRecipesTarget={bakedRecipesTarget}
      paymentTarget={paymentTarget}
      deliveryTarget={deliveryTarget}
      cancelButtonTarget={cancelTarget}
      weeksOfFood={weeksOfFood}
      isLegacyCustomer={isLegacyCustomer}
      confirmationButtonText={
        isLegacyCustomer ? confirmationButtonText : checkoutButtonText
      }
      checkoutUrl={addPupCart?.checkout_url}
    />
  );
};

AddPupPlanSummaryContainer.displayName = 'AddPupPlanSummaryContainer';

AddPupPlanSummaryContainer.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadingText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  boxheadline: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  petForm: PropTypes.object.isRequired, // TODO(James) complete
  productSku: PropTypes.string.isRequired,
  subscriptionSku: PropTypes.string.isRequired,
  starterBox: PropTypes.object.isRequired,
  weeksOfFood: PropTypes.number.isRequired,
  cancelTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  pupInfoTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  mealPrepTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  freshRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  bakedRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paymentTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  deliveryTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  isLegacyCustomer: PropTypes.bool.isRequired,
  cartId: PropTypes.string,
  confirmationButtonText: PropTypes.string.isRequired,
  checkoutButtonText: PropTypes.string.isRequired,
};
