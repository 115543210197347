'use client';
import styled from 'styled-components';

export const StyledInfoContainer = styled.div`
  background-color: ${props => props.theme.colors.background.base};
`;

export const Container = styled.div`
  margin: auto;
  max-width: 512px;
`;

export const HeadingContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(15, 2)};
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(12, 0, 54, 0)};
`;

export const NoPaymentMethodText = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningPrimary};
  padding: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${props => props.theme.spacing(3)};

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;
