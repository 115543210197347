import Link from 'next/link';
import styled from 'styled-components';
import { Container as InfoLinkContainer } from '@web/account/InfoLink/styles';

export const Container = styled.div`
  margin: auto;
  max-width: 710px;
  padding: ${({ theme }) => theme.spacing(16, 4)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(16, 0)};
  }
`;

export const HeadingContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 2, 12, 2)};
`;

export const StyledIntro = styled.p`
  color: ${props => props.theme.colors.text.primary};
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(6)};

  ${InfoLinkContainer} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  gap: ${props => props.theme.spacing(4)};
  background-color: ${props => props.theme.colors.background.lightest};
  padding: ${props => props.theme.spacing(10)};
`;

export const InfoTitle = styled.p`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
`;
export const InfoSubtitle = styled.p`
  ${props => props.theme.typography.heading6}
  color: ${props => props.theme.colors.text.secondary};
  font-size: 16px;
`;
export const InfoLabel = styled.p`
  ${props => props.theme.typography.heading6}
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing(2)};
  font-size: 16px;
`;

export const InfoLine = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
`;

export const RecipeInfoContainer = styled.div``;

export const InfoListItem = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
`;

export const InfoFooter = styled.p`
  ${props => props.theme.typography.heading6}
  color: ${props => props.theme.colors.text.primary};
  font-size: 16px;
`;

export const StyledLink = styled(Link)`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.secondary.chia};
  text-decoration: underline;
`;
