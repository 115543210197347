'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { Footer, LoadingView } from '@web/components';
import { BackgroundColorContainer, Heading } from '@web/atoms';
import { RedirectToLogin } from '../../router';
import { useSessionCustomer } from '../../customer';
import { usePets } from '../../pets';
import { useReporter } from '../../reporter';
import { DogSubscriptionContainer } from '../DogSubscriptionContainer';
import { AccountHeader } from '../AccountHeader';
import { Container, HeadingContainer, ButtonContainer } from './styles';
import { userViewsEditSubscriptionsPage } from './events';

export const EditSubscriptionsContainer = ({
  links,
  logoUrl,
  logoSrText,
  headerTitleLabel,
  reactivateUrlTarget,
  reactivateButtonLabel,
  cancelButtonLabel,
  cancelUrl,
  footerContent,
}) => {
  const reporter = useReporter();
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { pets, isLoading: arePetsLoading } = usePets();
  const isLoading = isCustomerLoading || arePetsLoading;

  useEffect(() => {
    if (!isLoading) reporter.tag(userViewsEditSubscriptionsPage());
  }, [isLoading, reporter]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!isActive && !isLoading) {
    return <RedirectToLogin />;
  }

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <BackgroundColorContainer $bgColor="background.base">
        <Container>
          <HeadingContainer>
            <Heading
              headingLevel="h3"
              typography="heading1"
              headingText={headerTitleLabel}
              position="center"
              horizontalPadding={2}
              verticalPadding={1}
            />
          </HeadingContainer>
          {pets &&
            pets.map(({ id, name, breeds, recommendation_version }) => {
              return (
                <DogSubscriptionContainer
                  key={id}
                  petName={name}
                  petBreedsNames={breeds.map(breed => breed.name)}
                  petId={id}
                  reactivateUrlTarget={reactivateUrlTarget}
                  reactivateButtonLabel={reactivateButtonLabel}
                />
              );
            })}
          <ButtonContainer>
            <LinkButton fullWidth href={cancelUrl}>
              {cancelButtonLabel}
            </LinkButton>
          </ButtonContainer>
        </Container>
      </BackgroundColorContainer>
      <Footer {...footerContent} />
    </>
  );
};

EditSubscriptionsContainer.displayName = 'EditSubscriptionsContainer';
EditSubscriptionsContainer.propTypes = {
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  headerTitleLabel: PropTypes.string.isRequired,
  reactivateUrlTarget: PropTypes.string.isRequired,
  reactivateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  footerContent: PropTypes.object.isRequired,
};
