'use client';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useElements, PaymentElement } from '@stripe/react-stripe-js';
import capitalize from 'lodash/capitalize';
import { Button, LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  PaymentInfoCard,
  Footer,
  LoadingView,
  ServerSideErrorModal,
} from '@web/components';
import { BackgroundColorContainer, Heading } from '@web/atoms';
import { RedirectToLogin } from '../../router';
import { usePayment, useStripe } from '../../payment';
import { PAYMENT_METHOD_IMAGES } from '../../payment/constants';
import { useAddress, useSessionCustomer } from '../../customer';
import { useReporter } from '../../reporter';
import { TOAST_SERVER_MESSAGE } from '../constants';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { ApiResult, wrapErrorWithResult } from '../../api';
import { AccountHeader } from '../AccountHeader';
import stripeText from './pay-securely-with-stripe.svg';
import { NO_PAYMENT_TEXT } from './constants';
import {
  Container,
  HeadingContainer,
  StyledSection,
  StyledInfoLineWrapper,
  PaymentCardHeader,
  StyledInfo,
  PaymentCardHeaderWrapper,
  HrTag,
  ButtonContainer,
  StyledNoPaymentMethodText,
} from './styles';
import {
  userViewEditPayment,
  paymentCardClicked,
  googlePayButtonClicked,
  applePayButtonClicked,
  userUpdatedPaymentMethod,
  billingInformationCompleted,
} from './events';

export const EditPaymentContainer = ({
  footerContent,
  headerTitleLabel,
  saveBtnLabel,
  cancelBtnLabel,
  cancelBtnTarget,
  links,
  logoUrl,
  logoSrText,
  onPaymentChanged,
}) => {
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { address } = useAddress(customer?.id);
  const { payment, isLoading: paymentIsLoading } = usePayment(customer?.id);
  const { stripe, stripeError, confirmSetupIntent, createPaymentMethod } =
    useStripe();
  const elements = useElements();
  const reporter = useReporter();
  const previousEventFired = useRef(null);
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessageInfo, setErrorMessageInfo] = useState(
    stripeError ? stripeError.message : null,
  );
  const closeErrorModal = () => setErrorMessageInfo(null);

  const paymentInfo =
    payment && Object.values(payment)?.length && payment?.is_active ? (
      <StyledInfoLineWrapper>
        {
          PAYMENT_METHOD_IMAGES[
            payment?.wallet || payment?.brand?.toLowerCase()
          ]
        }{' '}
        {capitalize(payment?.brand)} ending in •••• {payment?.last_four_digits}
      </StyledInfoLineWrapper>
    ) : (
      <StyledNoPaymentMethodText>{NO_PAYMENT_TEXT}</StyledNoPaymentMethodText>
    );
  useEffect(() => {
    if (!isCustomerLoading) {
      reporter.tag(userViewEditPayment());
    }
  }, [isCustomerLoading, reporter]);

  const onSubmit = async paymentMethod => {
    setIsSubmitting(true);
    const elementsSubmit = await elements.submit();

    const submitResult = elementsSubmit?.error
      ? wrapErrorWithResult(elementsSubmit?.error)
      : ApiResult.success();
    let confirmationResult = await ApiResult.flatMapAsync(submitResult, () =>
      confirmSetupIntent({
        stripe,
        elements,
        customer,
        address,
      }),
    );

    const paymentMethodResult = await ApiResult.flatMapAsync(
      confirmationResult,
      () => createPaymentMethod(customer.id),
    );

    setIsSubmitting(false);

    ApiResult.match(paymentMethodResult, {
      success: () => {
        reporter.tag(userUpdatedPaymentMethod());
        onPaymentChanged();
        showToast({
          status: 'success',
          message: 'Your payment method has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: TOAST_SERVER_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
        paymentInvalid: error =>
          showToast({
            status: 'error',
            message:
              error?.message ??
              'There was an error processing your credit card.',
            headline: 'Error Message',
          }),
        card_error: error =>
          showToast({
            status: 'error',
            message: error.message,
            headline: 'Error Message',
          }),
      },
    });

    return paymentMethodResult;
  };
  const isLoading = isCustomerLoading || paymentIsLoading;

  useEffect(() => {
    if (!isLoading) {
      const paymentElement = elements?.getElement('payment');
      if (paymentElement) {
        paymentElement.on('change', event => {
          const eventType = event?.value?.type;
          if (eventType !== previousEventFired.current) {
            previousEventFired.current = eventType;
            switch (eventType) {
              case 'google_pay':
                reporter.tag(googlePayButtonClicked());
                break;
              case 'apple_pay':
                reporter.tag(applePayButtonClicked());
                break;
              case 'card':
                reporter.tag(paymentCardClicked());
                break;
              default:
            }
          }
          const completedForm = event?.complete;
          if (completedForm)
            reporter.tag(billingInformationCompleted(eventType));
        });
      }
    }
  }, [elements, reporter, isLoading]);
  if (isLoading) {
    return <LoadingView />;
  }

  if (!isActive) {
    return <RedirectToLogin />;
  }

  const paymentElementOptions = {
    layout: {
      type: 'accordion',
    },
    fields: {
      billingDetails: {
        email: 'never',
        phone: 'never',
        address: {
          country: 'never',
        },
      },
    },
  };

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <BackgroundColorContainer $bgColor="background.base">
        <Container>
          <HeadingContainer>
            <Heading
              headingLevel="h3"
              typography="heading1"
              headingText={headerTitleLabel}
              position="center"
              horizontalPadding={2}
              verticalPadding={1}
            />
          </HeadingContainer>
          <StyledSection>
            <PaymentCardHeaderWrapper>
              <PaymentCardHeader>Current payment method</PaymentCardHeader>
            </PaymentCardHeaderWrapper>

            <PaymentInfoCard
              title={''}
              href="/account/edit-payment"
              infoList={[paymentInfo]}
              showPayment={payment?.is_active}
            />

            <HrTag />
          </StyledSection>
          <StyledSection>
            <PaymentCardHeaderWrapper>
              <PaymentCardHeader>Change payment method</PaymentCardHeader>
            </PaymentCardHeaderWrapper>

            <PaymentCardHeaderWrapper>
              <StyledInfo>
                Changing your payment method will update your default payment
                method for all purchases
              </StyledInfo>
            </PaymentCardHeaderWrapper>
            <StyledInfo>
              <ResponsiveImage
                src={stripeText.src}
                alt="Pay securely with Stripe"
                width={stripeText.width}
                height={stripeText.height}
              />
            </StyledInfo>
            <HrTag />
          </StyledSection>
          <PaymentElement options={paymentElementOptions} />
          <ButtonContainer>
            <Button
              fullWidth
              disabled={isSubmitting}
              type="submit"
              onClick={onSubmit}
            >
              {saveBtnLabel}
            </Button>
            <LinkButton fullWidth href={cancelBtnTarget} variant="secondary">
              {cancelBtnLabel}
            </LinkButton>
          </ButtonContainer>
        </Container>
      </BackgroundColorContainer>
      <ServerSideErrorModal
        shown={errorMessageInfo !== null}
        headerText={errorMessageInfo?.title ?? ''}
        errorMessage={errorMessageInfo?.message ?? ''}
        onCloseModal={closeErrorModal}
        variant={'secondary'}
        showCloseButton={true}
      />
      <Footer {...footerContent} />
    </>
  );
};

EditPaymentContainer.displayName = 'EditPaymentContainer';
EditPaymentContainer.propTypes = {
  headerTitleLabel: PropTypes.string.isRequired,
  saveBtnLabel: PropTypes.string.isRequired,
  cancelBtnLabel: PropTypes.string.isRequired,
  cancelBtnTarget: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  onPaymentChanged: PropTypes.func.isRequired,
  footerContent: PropTypes.object.isRequired,
};
