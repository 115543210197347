'use client';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { interpolate } from '@format/interpolate';
import {
  Footer,
  LoadingView,
  OllieAppBanner,
  OllieAppCtaBanner,
} from '@web/components';
import { DEFAULT_OLLIE_APP_BANNER_PROPS } from '@web/components/OllieAppBanner/constants.js';
import { useAddress, useSessionCustomer } from '../../customer';
import { RedirectToLogin } from '../../router';
import { CMSBlok } from '../../cms';
import { useSubscription } from '../../subscriptions';
import {
  OLLIE_APP_BANNER_KEY,
  SHOW_OLLIE_APP_BANNER_V2,
  SHOW_ADD_ONS_KEY,
  SHOW_QUICK_ADD_EXTRAS_SECTION,
  SHOW_PORTION_SECTION_WEB,
  useExperiment,
} from '../../experiments';
import {
  useSubscriptionAddonProducts,
  useSupplements,
  useTreats,
} from '../../addons';
import { useToast } from '../../Toast';
import { useProduct } from '../../products';
import { useCurrentPet } from '../useCurrentPet';
import { WelcomeBack } from '../WelcomeBack';
import { DogSelection } from '../DogSelection';
import { YourPup } from '../YourPup';
import { NextBox } from '../NextBox';
import { BoxManagementNav } from '../BoxManagementNav';
import { AccountHeader } from '../AccountHeader';
import { PrefixedReporterProvider, useReporter } from '../../reporter';
import { ReferAFriend } from '../ReferAFriend';
import { QuickAddExtrasContainer } from '../QuickAddExtrasContainer';
import { usePayment } from '../../payment';
import { usePets, useLatestOrderForPetId } from '../../pets';
import { useOrder } from '../../orders';
import { handleSubscriptionAddonChanges } from './functions';
import { addDog, viewDashboardPageEvent } from './events';
import {
  getDogs,
  getYourPupProps,
  getNextBoxProps,
  getBoxManagementNavProps,
  getFilteredRecommendedAddons,
} from './dataParsers';
import {
  StyledAccountContainer,
  HeaderContainer,
  ContentContainer,
  YourPupContainer,
  SectionContainer,
} from './styles';
// TODO remove constants
import { ACTIVE_STATUS } from './constants';

export const AccountContainer = ({
  headline,
  subheadline,
  dogSelectorLabel,
  addPupBtnLabel,
  addPupBtnUrl,
  header,
  appCtaBanner,
  yourPup,
  discountAmount,
  lowerContent,
  recommendedAddons,
  portionSection,
  modals,
  footer,
}) => {
  const router = useRouter();
  const reporter = useReporter();
  const { showToast } = useToast();
  const { pets, isLoading: arePetsLoading } = usePets({
    hasSubscription: true,
  });
  const { pet, isLoading: isPetLoading } = useCurrentPet();

  const petOptions = useMemo(() => getDogs(pets), [pets]);
  const selectedPetId = pet?.id;
  const { value: showOllieAppBanner } = useExperiment(
    OLLIE_APP_BANNER_KEY,
    false,
  );
  const { value: showOllieAppBannerV2 } = useExperiment(
    SHOW_OLLIE_APP_BANNER_V2,
    false,
  );

  const { value: showQuickAddExtrasSection } = useExperiment(
    SHOW_QUICK_ADD_EXTRAS_SECTION,
    false,
  );
  const { value: showAddons } = useExperiment(SHOW_ADD_ONS_KEY, false);

  const { value: showPortionSection } = useExperiment(
    SHOW_PORTION_SECTION_WEB,
    false,
  );

  const {
    nextDeliveryDate,
    subscription,
    isLoading: isSubscriptionLoading,
  } = useSubscription(selectedPetId);

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const { order: latestOrderForPetId, isLoading: isLatestOrderForPetLoading } =
    useLatestOrderForPetId(selectedPetId);

  const {
    order: currentSubscriptionOrder,
    isLoading: isCurrentSubscriptionOrderLoading,
  } = useOrder(subscription?.current_order_id);

  // The most recent order for pet, a fallback for feeding guidelines if there is no current_order
  const latestOrder = currentSubscriptionOrder ?? latestOrderForPetId;

  const { isLoading: isTreatsLoading, products: allTreats } = useTreats({
    petId: selectedPetId,
  });
  const { isLoading: isSupplementsLoading, products: allSupplements } =
    useSupplements({ petId: selectedPetId });

  const { product: nextOrderProduct, isLoading: isNextProductLoading } =
    useProduct(currentSubscriptionOrder?.items[0].sku);

  const {
    product: subscriptionProduct,
    isLoading: isSubscriptionProductLoading,
  } = useProduct(subscription?.sku);

  const {
    data: subAddonProducts,
    createAddon,
    isValidating: isSubscriptionAddonsValidating,
  } = useSubscriptionAddonProducts(subscription?.id, {}, showAddons);

  const { payment, isLoading: isPaymentLoading } = usePayment(customer?.id);
  const { address, isLoading: isAddressLoading } = useAddress(customer?.id);

  const subscribedAddonSkus = subAddonProducts?.map(addon => addon.sku);

  const onQuickAddExtraClick = async addon => {
    await handleSubscriptionAddonChanges(createAddon, addon, showToast);
  };

  const filteredRecommendedAddons = getFilteredRecommendedAddons(
    recommendedAddons,
    subscribedAddonSkus,
    allTreats,
    allSupplements,
  );

  const hasFourOrMoreAddons = subscribedAddonSkus?.length >= 4;
  const onSelectPet = id => {
    reporter.tag(addDog('dropdown'));
    // URL is duplicated because second param is deprecated `as`
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, pet_id: id },
      },
      {
        pathname: router.pathname,
        query: { ...router.query, pet_id: id },
      },
      { shallow: true },
    );
  };

  const isLoading =
    isPetLoading ||
    isSubscriptionLoading ||
    isCustomerLoading ||
    isNextProductLoading ||
    isSubscriptionProductLoading ||
    isPaymentLoading ||
    isAddressLoading ||
    arePetsLoading ||
    isCurrentSubscriptionOrderLoading ||
    isLatestOrderForPetLoading ||
    isTreatsLoading ||
    isSupplementsLoading;

  useEffect(() => {
    if (!isLoading) reporter.tag(viewDashboardPageEvent());
  }, [reporter, isLoading]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  const subscriptionIsActive = subscription.status === ACTIVE_STATUS;

  return (
    <StyledAccountContainer>
      <AccountHeader {...header} name={customer.first_name} />
      <ContentContainer>
        <HeaderContainer>
          <WelcomeBack
            headline={interpolate(headline, { name: customer.first_name })}
            subheadline={interpolate(subheadline, { petName: pet.name })}
          />
          <DogSelection
            options={petOptions}
            onChange={onSelectPet}
            value={selectedPetId}
            label={dogSelectorLabel}
            addPupLinkUrl={addPupBtnUrl}
            addPupLinkLabel={addPupBtnLabel}
          />
        </HeaderContainer>
        {!customer.is_mobile_user && (
          <SectionContainer>
            {showOllieAppBannerV2 ? (
              <OllieAppCtaBanner {...appCtaBanner} />
            ) : (
              showOllieAppBanner && (
                <OllieAppBanner {...DEFAULT_OLLIE_APP_BANNER_PROPS} />
              )
            )}
          </SectionContainer>
        )}
        <YourPupContainer>
          <YourPup
            {...getYourPupProps(yourPup, pet, customer, latestOrder)}
            hasActiveSubscription={subscriptionIsActive}
          />
        </YourPupContainer>
        {subscriptionIsActive && (
          <>
            <SectionContainer>
              <NextBox
                {...getNextBoxProps({
                  nextOrderProduct,
                  subscriptionProduct,
                  pet,
                  nextDeliveryDate,
                  order: currentSubscriptionOrder,
                  subscription,
                  subAddonProducts,
                })}
                subscriptionAddons={subAddonProducts || []}
                showAddons={showAddons}
                orderId={subscription?.current_order_id || latestOrder?.id}
              />
            </SectionContainer>
            {showQuickAddExtrasSection &&
              !hasFourOrMoreAddons &&
              subscriptionIsActive && (
                <PrefixedReporterProvider prefix="">
                  <SectionContainer>
                    <QuickAddExtrasContainer
                      sectionTitle={'Recommended'}
                      seeExtrasLinkText={'See all Extras'}
                      editExtrasLink={{
                        pathname: '/account/edit-recipes/extras',
                        query: { pet_id: pet.id, direct_edit: true },
                      }}
                      recommendedAddons={filteredRecommendedAddons}
                      onQuickAddExtraClick={onQuickAddExtraClick}
                      isSubscriptionAddonsValidating={
                        isSubscriptionAddonsValidating
                      }
                      addonModals={modals}
                      allTreats={allTreats}
                      allSupplements={allSupplements}
                      subscribedAddons={subAddonProducts}
                    />
                  </SectionContainer>
                </PrefixedReporterProvider>
              )}
            <SectionContainer>
              <BoxManagementNav
                {...getBoxManagementNavProps({
                  nextDeliveryDate,
                  subscription,
                  address,
                  payment,
                  pet,
                  product: subscriptionProduct,
                  currentOrder: currentSubscriptionOrder,
                  subAddonProducts,
                })}
                showAddons={showAddons}
                petId={selectedPetId}
                brightbackInfo={{
                  customerId: customer.id,
                  customerEmail: customer.email,
                  petSubscriptionId: pet.subscription_id,
                }}
                showPortionSection={showPortionSection}
                portionSectionModalContent={portionSection}
              />
            </SectionContainer>
          </>
        )}
      </ContentContainer>
      <ContentContainer>
        <ReferAFriend discountAmount={discountAmount} />
        {lowerContent.map((content, idx) => (
          <SectionContainer key={idx}>
            <CMSBlok key={content._uid} content={content} />
          </SectionContainer>
        ))}
      </ContentContainer>
      <Footer {...footer} />
    </StyledAccountContainer>
  );
};
AccountContainer.displayName = 'AccountContainer';
AccountContainer.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
  dogSelectorLabel: PropTypes.string.isRequired,
  addPupBtnLabel: PropTypes.string.isRequired,
  addPupBtnUrl: PropTypes.string.isRequired,
  header: PropTypes.object.isRequired,
  yourPup: PropTypes.object.isRequired,
  appCtaBanner: PropTypes.object.isRequired,
  discountAmount: PropTypes.number.isRequired,
  lowerContent: PropTypes.array,
  recommendedAddons: PropTypes.array,
  portionSection: PropTypes.object,
  modals: PropTypes.array,
  footer: PropTypes.object.isRequired,
};
