'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Footer, LoadingView } from '@web/components';
import { BackgroundColorContainer, Heading } from '@web/atoms';
import { EditPasswordForm } from '../EditPasswordForm';
import { useSessionCustomer } from '../../customer';
import { RedirectToLogin } from '../../router';
import { ApiResult } from '../../api';
import { useReporter } from '../../reporter';
import {
  TOAST_SERVER_MESSAGE,
  TOAST_CLIENT_INCORRECT_PASSWORD_MESSAGE,
} from '../constants';
import { useToast } from '../../Toast';
import { AccountHeader } from '../AccountHeader';
import { FOOTER_CONTENT } from '../../testing/constants';
import { StyledEditPasswordContainer, HeadingContainer } from './styles.js';
import { userViewEditPassword, clickUpdatePassword } from './events';

export const EditPasswordContainer = ({
  title,
  form,
  links,
  logoUrl,
  logoSrText,
}) => {
  const {
    updatePassword,
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const reporter = useReporter();
  const router = useRouter();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reporter.tag(userViewEditPassword());
  }, [reporter]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading) {
    return <LoadingView />;
  }

  const onClickUpdate = () => {
    reporter.tag(clickUpdatePassword());
  };

  const onSubmit = async password => {
    setIsSubmitting(true);
    onClickUpdate();
    const result = await updatePassword(password);
    ApiResult.match(result, {
      success: () => {
        router.push(`/account/`);
        showToast({
          status: 'success',
          message: 'Your password has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: TOAST_SERVER_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: error => {
          showToast({
            status: 'error',
            message: TOAST_CLIENT_INCORRECT_PASSWORD_MESSAGE,
            headline: 'Error Message',
          });
          reporter.error(error.original);
        },
      },
    });
    ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
  };

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <BackgroundColorContainer $bgColor="background.base">
        <StyledEditPasswordContainer>
          <HeadingContainer>
            <Heading
              headingLevel="h1"
              headingText={title}
              position="center"
              typography="heading1"
              horizontalPadding={2}
            />
          </HeadingContainer>
          <EditPasswordForm
            {...form}
            onSubmit={onSubmit}
            cancelUrl="/account/my-info"
            isSubmitting={isSubmitting}
          />
        </StyledEditPasswordContainer>
      </BackgroundColorContainer>
      <Footer {...FOOTER_CONTENT} />
    </>
  );
};
EditPasswordContainer.displayName = 'EditPasswordContainer';
EditPasswordContainer.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
};
