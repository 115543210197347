import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, List } from '@web/molecules';
import { useReporter } from '../../reporter';
import curvedArrowLeft from '../../images/Icons/curved-arrow-left.svg';
import { SocialButton } from '../SocialButton';
import { userClicksOnDownloadButton } from './events';
import {
  Container,
  Content,
  Title,
  ListContainer,
  DesktopImageContainer,
  MobileImageContainer,
  QRSectionContainer,
  QRImageContainer,
  QRExplanationContainer,
  StyledQRExplanation,
  CTASectionContainer,
} from './styles';

export const OllieAppCtaBanner = ({
  title,
  desktopImage,
  mobileImage,
  list,
  qrCode,
  qrExplanation,
  cta,
}) => {
  const reporter = useReporter();
  return (
    <>
      <Container>
        <DesktopImageContainer>
          <ResponsiveImage {...desktopImage} />
        </DesktopImageContainer>
        <MobileImageContainer>
          <ResponsiveImage {...mobileImage} />
        </MobileImageContainer>
        <Content>
          <Title>{title}</Title>
          <ListContainer>
            <List
              items={list.items}
              typography="bodyBold"
              icon={list.icon}
              iconColor="secondary.yam"
              iconSize="17px"
              gap={4}
            />
          </ListContainer>
        </Content>
        <QRSectionContainer>
          <QRImageContainer>
            <ResponsiveImage {...qrCode} height={140} width={140} />
          </QRImageContainer>
          <QRExplanationContainer>
            <ResponsiveImage
              alt="left arrow"
              src={curvedArrowLeft}
              height={70}
              width={70}
            />
            <StyledQRExplanation>{qrExplanation}</StyledQRExplanation>
          </QRExplanationContainer>
        </QRSectionContainer>
        {!!cta.length && (
          <CTASectionContainer>
            {cta.map((button, key) => (
              <SocialButton
                key={key}
                {...button}
                onClick={() =>
                  reporter.tag(userClicksOnDownloadButton(button.appType))
                }
              />
            ))}
          </CTASectionContainer>
        )}
      </Container>
    </>
  );
};

OllieAppCtaBanner.displayName = 'OllieAppCtaBanner';
OllieAppCtaBanner.propTypes = {
  title: PropTypes.string.isRequired,
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  qrCode: PropTypes.object.isRequired,
  qrExplanation: PropTypes.string.isRequired,
  cta: PropTypes.array.isRequired,
};
