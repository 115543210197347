import { useCallback } from 'react';
import { useService, useAuthenticatedSWR } from '../api';
import { AddPupCartService } from './service';
import { ADD_PUP_CART_BASE_URL } from './constants';

export const useAddPupCart = cartId => {
  const service = useService(AddPupCartService);
  const { data, mutate, error, isValidating } = useAuthenticatedSWR(
    cartId ? `${ADD_PUP_CART_BASE_URL}${cartId}/` : null,
  );

  const createCart = async () => {
    try {
      return await service.createAddPupCart();
    } catch (error) {
      return error;
    }
  };

  const addPupToCart = async (cartId, pet) => {
    try {
      return await service.addPupToCart(cartId, pet);
    } catch (error) {
      return error;
    }
  };

  const createAddPupCart = async pet => {
    const cartResponse = await createCart();
    const cartId = cartResponse?.data?.cart_id;

    if (cartId) {
      return await addPupToCart(cartId, pet);
    } else {
      return cartResponse;
    }
  };

  const revalidate = useCallback(() => mutate(), [mutate]);

  return {
    isLoading: cartId ? !data && !error : false,
    data,
    error,
    createAddPupCart,
    isValidating,
    revalidate,
  };
};
