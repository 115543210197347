import { useCallback } from 'react';
import { useAuthenticatedSWR } from '../api';
import { ADD_PUP_CART_BASE_URL } from './constants';

export const useAddPupPlanCombos = (cartId, petId, mealPlanId, cadence) => {
  const isRequiredParamsAvailable = cartId && petId && mealPlanId;
  const url = `${ADD_PUP_CART_BASE_URL}${cartId}/pets/${petId}/plans/${mealPlanId}/${
    cadence ? `?cadence=${cadence}` : ''
  }`;

  const { data, mutate, error, isValidating } = useAuthenticatedSWR(
    isRequiredParamsAvailable ? url : null,
  );

  const revalidate = useCallback(() => mutate(), [mutate]);

  return {
    isLoading: isRequiredParamsAvailable
      ? isValidating && !data && !error
      : false,
    data,
    error,
    revalidate,
  };
};
