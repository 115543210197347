import { BaseService } from '../api';
import { ADD_PUP_CART_BASE_URL } from './constants';

export class AddPupCartService extends BaseService {
  createAddPupCart = async () => await this.client.post(ADD_PUP_CART_BASE_URL);

  getAddPupCart = async cartId =>
    await this.client.get(`${ADD_PUP_CART_BASE_URL}${cartId}/`);

  addPupToCart = async (cartId, pet) =>
    await this.client.put(`${ADD_PUP_CART_BASE_URL}${cartId}/pets/`, pet);
}
