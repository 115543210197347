'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Footer, LoadingView } from '@web/components';
import { FeedingGuidelinesInfoForm } from '@web/components/FeedingGuidelinesInfoForm';
import { RedirectToLogin } from '../../router';
import { FOOTER_CONTENT } from '../../testing/constants';
import { AccountHeader } from '../AccountHeader';
import { useSessionCustomer } from '../../customer';
import { useCurrentPet } from '../useCurrentPet';
import { useSubscription } from '../../subscriptions';
import { useOrder, useFeedingGuidelines } from '../../orders';
import { useLatestOrderForPetId } from '../../pets';
import {
  getBoxContentByPlanType,
  formatFreshRecipes,
  getServingDetails,
} from './functions';
import { StyledFeedingGuidelinesContainer } from './styles';

export const FeedingGuidelinesContainer = ({
  title,
  links,
  logoUrl,
  logoSrText,
}) => {
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const { pet, isLoading: isPetLoading } = useCurrentPet();

  const { subscription, isLoading: isSubscriptionLoading } = useSubscription(
    pet?.id,
  );

  const { order: currentOrder, isLoading: isCurrentOrderLoading } = useOrder(
    subscription?.current_order_id,
  );

  const { order: latestOrderForPetId, isLoading: isLatestOrderForPetLoading } =
    useLatestOrderForPetId(pet?.id);

  const latestOrder = currentOrder ?? latestOrderForPetId;
  const orderId = subscription?.current_order_id || latestOrder?.id;

  const {
    isLoading: feedingGuidelinesLoading,
    data: feedingGuidelines,
    error: feedingGuidelinesError,
    getLatestFeedingGuidelines,
  } = useFeedingGuidelines(orderId);

  // in the case where there's a one off box without a subscription, and the
  // useFeedingGuidelines call fails, getLatestFeedingGuidelines will be triggered
  const {
    data: latestFeedingGuidelines,
    isLoading: latestFeedingGuidelinesLoading,
  } = getLatestFeedingGuidelines(feedingGuidelinesError?.response);

  const validFeedingGuidelines = feedingGuidelines ?? latestFeedingGuidelines;

  const portionSize = validFeedingGuidelines?.portion_size;

  const freshRecipes =
    validFeedingGuidelines?.recipes?.map(recipe => recipe.slug) || [];
  const bakedRecipes =
    validFeedingGuidelines?.baked_recipes?.map(recipe => recipe.slug) || [];

  const hasFreshRecipes = freshRecipes.length > 0;
  const hasBakedRecipes = bakedRecipes.length > 0;

  //must check if order has fresh or dry recipies due to BE bug where sometimes both
  //packs_per_day and cups_per_day have a value even if the pup does not have a mixed plan
  const packsPerDay = hasFreshRecipes
    ? validFeedingGuidelines?.packs_per_day
    : '';
  const cupsPerDay = hasBakedRecipes
    ? validFeedingGuidelines?.cups_per_day
    : '';
  const packsPerMeal = hasFreshRecipes
    ? validFeedingGuidelines?.packs_per_meal
    : '';
  const cupsPerMeal = hasBakedRecipes
    ? validFeedingGuidelines?.cups_per_meal
    : '';
  const packsPerBox = validFeedingGuidelines?.num_fresh_packs;
  const bagsPerBox = validFeedingGuidelines?.bag_count;

  const mealPlanContent = getBoxContentByPlanType({
    productType: validFeedingGuidelines?.product_type,
    portionSize,
    packsPerBox,
    bagsPerBox,
  });

  const feedingGuidelinesProps = validFeedingGuidelines
    ? {
        title,
        petName: validFeedingGuidelines?.pet_name,
        mealPlanName: mealPlanContent?.planName,
        dailyServing: getServingDetails(packsPerDay, cupsPerDay),
        calories: parseFloat(validFeedingGuidelines?.total_kcal_per_day),
        servingPerMeal: getServingDetails(packsPerMeal, cupsPerMeal),
        foodPerBox: mealPlanContent?.foodPerBox,
        frequency: validFeedingGuidelines?.frequency,
        hasFreshRecipes,
        hasBakedRecipes,
        freshRecipes: formatFreshRecipes(validFeedingGuidelines?.recipes) || [],
        bakedRecipes:
          validFeedingGuidelines?.baked_recipes?.map(recipe => recipe.slug) ||
          [],
      }
    : {};

  const isLoading =
    isCustomerLoading ||
    isPetLoading ||
    isSubscriptionLoading ||
    isCurrentOrderLoading ||
    isLatestOrderForPetLoading ||
    feedingGuidelinesLoading ||
    latestFeedingGuidelinesLoading;

  if (!isLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }
  return (
    <StyledFeedingGuidelinesContainer>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      {validFeedingGuidelines && (
        <FeedingGuidelinesInfoForm {...feedingGuidelinesProps} />
      )}
      <Footer {...FOOTER_CONTENT} />
    </StyledFeedingGuidelinesContainer>
  );
};

FeedingGuidelinesContainer.displayName = 'FeedingGuidelinesContainer';

FeedingGuidelinesContainer.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
};
