export const getBoxContentByPlanType = ({
  productType,
  portionSize,
  packsPerBox,
  bagsPerBox,
}) => {
  const mealType = portionSize === '0.5' ? `H${productType}` : productType;
  const bagText = bagsPerBox !== '1' ? 'bags' : 'bag';

  const productContent = {
    FR: {
      planName: 'FULL FRESH',
      foodPerBox: `${packsPerBox} packs`,
    },
    HFR: {
      planName: 'HALF FRESH',
      foodPerBox: `${packsPerBox} packs`,
    },
    DY: {
      planName: 'BAKED',
      foodPerBox: `${bagsPerBox} ${bagText}`,
    },
    MX: {
      planName: 'MIXED',
      foodPerBox: `${packsPerBox} packs and ${bagsPerBox} ${bagText}`,
    },
  };

  return productContent[mealType];
};

//handles FG endpoint values since the product endpoint returns packs
//per day as decimals and FG returns fractions
export const getServingDetails = (packsPerDay, cupsPerDay) => {
  //checks if '/' is in the second position to determine if it's a fraction under 1 pack
  // Takes into account if value is 1+ cup(s) with no fractions.
  const isMoreThanOneCup =
    (cupsPerDay?.length === 1 && cupsPerDay !== '1') ||
    (cupsPerDay?.length > 1 && cupsPerDay?.split('')[1] !== '/');
  const cupText = isMoreThanOneCup ? 'cups' : 'cup';
  const cupsPerDayText = isMoreThanOneCup
    ? cupsPerDay?.replace(' ', ' & ')
    : cupsPerDay;

  const isMoreThanOnePack =
    (packsPerDay?.length === 1 && packsPerDay !== '1') ||
    (packsPerDay?.length > 1 && packsPerDay?.split('')[1] !== '/');
  const packText = isMoreThanOnePack ? 'packs' : 'pack';
  const packsPerDayText = isMoreThanOnePack
    ? packsPerDay?.replace(' ', ' & ')
    : packsPerDay;

  const freshText = `${packsPerDayText} ${packText} of fresh food`;
  const bakedText = `${cupsPerDayText} ${cupText} of baked food`;

  return packsPerDay && cupsPerDay
    ? `${freshText} and ${bakedText}`
    : packsPerDay
    ? `${freshText}`
    : cupsPerDay
    ? `${bakedText}`
    : '';
};

export const formatFreshRecipes = recipes => {
  const recipeList = {
    chicken: 'Chicken dish with carrots',
    beef: 'Beef dish with sweet potatoes',
    pork: 'Pork dish with apples',
    turkey: 'Turkey dish with blueberries',
    lamb: 'Lamb dish with cranberries',
  };
  return recipes?.map(recipe => recipeList[recipe?.slug.toLocaleLowerCase()]);
};
