'use client';
import styled from 'styled-components';
import { HeadingStyled } from '../Heading/styles';

export const StyledDatePicker = styled.div`
  .react-datepicker__day-names {
    ${props => props.theme.typography.body}
    margin: ${({ theme }) => theme.spacing(7, 0, 4, 0)};
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: repeat(7, 1fr);
    display: grid;
    @media ${props => props.theme.tabletS} {
      justify-items: center;
      grid-column-gap: ${({ theme }) => theme.spacing(6)};
    }
  }

  .react-datepicker__month-container {
    text-align: center;
    background: ${props => props.theme.colors.background.lightest};
    border-radius: 32px;
    @media ${props => props.theme.tabletS} {
      float: left;
      padding: ${({ theme }) => theme.spacing(8, 20)};
    }
  }

  .react-datepicker__month {
    display: grid;
    grid-row-gap: ${({ theme }) => theme.spacing(1)};
  }

  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
    grid-column-gap: ${({ theme }) => theme.spacing(1)};
    @media ${props => props.theme.tabletS} {
      grid-column-gap: ${({ theme }) => theme.spacing(6)};
    }
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .date-picker-day {
    ${props => props.theme.typography.body}
    color: ${({ theme }) => theme.colors.text.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 31px;
    height: 31px;

    @media ${props => props.theme.tablet} {
      ${props => props.theme.typography.subheading2}
      width: 42px;
      height: 42px;
    }

    &:hover:not(.react-datepicker__day--disabled) {
      cursor: pointer;
    }

    &:hover:not(.react-datepicker__day--selected):not(
        .react-datepicker__day--disabled
      ) {
      border: 1px solid ${({ theme }) => theme.colors.secondary.kale};
      color: ${({ theme }) => theme.colors.text.primary};
    }

    &.react-datepicker__day--disabled {
      color: ${({ theme }) => theme.colors.neutral.bone};
    }

    &.react-datepicker__day--selected {
      background-color: ${({ theme }) => theme.colors.secondary.kale};
      color: ${({ theme }) => theme.colors.text.lightest};
    }

    &.react-datepicker__day--keyboard-selected:not(
        .react-datepicker__day--disabled
      ) {
      border: 1px solid ${({ theme }) => theme.colors.secondary.kale};
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.spacing(1, 0)};

  ${HeadingStyled} {
    background: ${props => props.theme.colors.background.lightest};
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
`;
