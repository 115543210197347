'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { Footer, LoadingView } from '@web/components';
import { ApiResult } from '../../api';
import { useReporter } from '../../reporter';
import {
  useAddress,
  useSessionCustomer,
  formToModel,
  modelToForm,
} from '../../customer';
import { RedirectToLogin } from '../../router';
import { EditAddressForm } from '../EditAddressForm';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { TOAST_SERVER_MESSAGE } from '../constants';
import { AccountHeader } from '../AccountHeader';
import {
  StyledEditAddressContainer,
  HeadingContainer,
  Content,
} from './styles.js';
import { userViewsEditAddress, updateAddress } from './events';

export const EditAddressContainer = ({
  heading,
  labelAddress,
  labelApt,
  labelZipCode,
  labelCity,
  labelState,
  labelPhoneNumber,
  labelSMSUpdates,
  labelSMSTerms,
  labelButtonSave,
  labelButtonCancel,
  cancelUrl,
  links,
  logoUrl,
  logoSrText,
  onAddressChanged,
  footerContent,
}) => {
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
    updateCustomer,
  } = useSessionCustomer(true);
  const reporter = useReporter();
  const {
    update: updateShippingAddress,
    address,
    isLoading: addressIsLoading,
  } = useAddress(customer?.id);
  const { showToast } = useToast();
  const isLoading = isCustomerLoading || addressIsLoading;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isLoading) reporter.tag(userViewsEditAddress());
  }, [isLoading, reporter]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }
  const defaultValues = modelToForm(address, customer);

  const onClickUpdate = () => {
    reporter.tag(updateAddress());
  };

  const handleSubmit = async address => {
    setIsSubmitting(true);
    onClickUpdate();
    const model = formToModel(address, customer);
    const response = await updateShippingAddress(model);

    // We need to update the whole customer to change is_sms
    const result = await ApiResult.flatMapAsync(response, async () =>
      ApiResult.callAsync(async () =>
        updateCustomer({
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
          is_sms: model.is_sms,
        }),
      ),
    );
    ApiResult.match(result, {
      success: () => {
        onAddressChanged();
        showToast({
          status: 'success',
          message: 'Your shipping address has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: TOAST_SERVER_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
      },
    });
    ApiResult.ifError(response, () => setTimeout(() => setIsSubmitting(false)));
    return result;
  };
  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <StyledEditAddressContainer>
        <Content>
          <HeadingContainer>
            <Heading
              headingLevel="h1"
              headingText={heading}
              position="center"
              typography={'heading1'}
              horizontalPadding={2}
            />
          </HeadingContainer>
          <EditAddressForm
            heading={heading}
            labelAddress={labelAddress}
            labelApt={labelApt}
            labelZipCode={labelZipCode}
            labelCity={labelCity}
            labelState={labelState}
            labelPhoneNumber={labelPhoneNumber}
            labelSMSUpdates={labelSMSUpdates}
            labelSMSTerms={labelSMSTerms}
            labelButtonSave={labelButtonSave}
            labelButtonCancel={labelButtonCancel}
            cancelUrl={cancelUrl}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            isSubmitting={isSubmitting}
          />
        </Content>
      </StyledEditAddressContainer>
      <Footer {...footerContent} />
    </>
  );
};

EditAddressContainer.displayName = 'EditAddressContainer';
EditAddressContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  labelAddress: PropTypes.string.isRequired,
  labelApt: PropTypes.string.isRequired,
  labelZipCode: PropTypes.string.isRequired,
  labelCity: PropTypes.string.isRequired,
  labelState: PropTypes.string.isRequired,
  labelPhoneNumber: PropTypes.string.isRequired,
  labelSMSUpdates: PropTypes.string.isRequired,
  labelSMSTerms: PropTypes.string.isRequired,
  labelResidentialAddress: PropTypes.string.isRequired,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  onAddressChanged: PropTypes.func.isRequired,
  footerContent: PropTypes.object.isRequired,
};
