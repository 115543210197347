export const ADD_PUP_CART_BASE_URL = `/api/v4/mx/add_pup/`;

export const CART_ID = '1';
export const NON_EXISTENT_CART_ID = '2';
export const PET_ID = 'UUID';
export const CADENCE = 4;
export const MEAL_PLAN_ID = '1';

export const starter_box = {
  cadence: 2,
  plan_type: 'MX',
  percent_of_calories: '1.0',
  price: '90.00',
  price_per_week: '45.00',
  discounted_price_per_week: '22.50',
  recipe_selection: {
    fresh: ['beef', 'lamb'],
    baked: ['chicken'],
  },
  items: [
    { name: 'Beef', sku: 'MP-FR-BEEF-300', qty: 7, unit_price: '30.00' },
    { name: 'Lamb', sku: 'MP-FR-LAMB-300', qty: 7, unit_price: '30.00' },
    {
      name: 'Chicken',
      sku: 'MP-BK-CHIC-300',
      qty: 7,
      unit_price: '30.00',
    },
  ],
};

export const extras = [
  {
    name: 'Chicken Jerky Strip',
    sku: 'JERKYSTRIPS-CH',
    frequency: 1,
    recurrence: null,
    qty: 1,
  },
  {
    name: 'Sweet Potato Crunch',
    sku: 'TREAT-SWEETPOTATOCRNCH-08',
    frequency: 1,
    recurrence: null,
    qty: 1,
  },
];
export const RESPONSE_PET = {
  id: 'UUID',
  name: 'Kona',
  weight: 20,
  ideal_weight: 20,
  activity_level: null,
  is_neutered: true,
  breed_ids: ['Goldendoodle'],
  allergy_ids: ['soy', 'corn'],
  gender: 'Female',
  birth_date: null,
  mealplan: null,
  extras,
  starter_box,
  subscription: null,
  stool_type: null,
};

export const FORM_PET = {
  name: 'Kona',
  weight: 20,
  ideal_weight: 20,
  activity_level: null,
  is_neutered: true,
  breeds: ['Goldendoodle'].join(','),
  allergys: ['soy', 'corn'].join(','),
  gender: 'Female',
  birth_date: null,
};

export const ADD_PUP_CART_PROPS = {
  cart_id: '1',
  customer: {
    first_name: 'Luke',
    last_name: 'Skywalker',
    email: 'example@example.com',
  },
  coupon_code: 'CX60',
  pets: [RESPONSE_PET],
  subtotal: '0.00',
  total_discount: '0.00',
  total_discount_mealplans: '0.00',
  total_tax: '0.00',
  total: '0.00',
};

export const MEAL_PLANS = [
  {
    plan_type: 'half_fresh',
    mealplan_id: '123',
    available_cadences: [2, 4, 6],
    default_cadence: 2,
  },
  {
    plan_type: 'fresh',
    mealplan_id: '123',
    available_cadences: [2, 4, 6],
    default_cadence: 4,
  },
  {
    plan_type: 'baked',
    mealplan_id: '123',
    available_cadences: [2, 4, 6],
    default_cadence: 4,
  },
];

export const ADD_PUP_PLAN_COMBOS = {
  avaliable_recipes: ['beef', 'chicken', 'turkey'],
  cadence: 4,
  plan_combinations: [
    {
      combination_id: 'uuid',
      name: 'Combo Name',
      description: 'Combo Description',
      recipes: ['beef', 'chicken'],
      price: '50',
      subscription_price: {
        box_price: '300',
        price_per_week: '50',
      },
    },
    {
      combination_id: 'uuid2',
      name: 'Combo Name 2',
      description: 'Combo 2 Description',
      recipes: ['beef'],
      price: '50',
      subscription_price: {
        box_price: '300',
        price_per_week: '50',
      },
    },
  ],
  metadata: {
    response_time: '2025-01-28T12:34:56Z',
    request_id: 'some-id',
  },
};

export const ADD_PUP_PLAN_TYPES = ['half_fresh', 'fresh', 'baked'];
export const CART_CREATION_ERROR_MESSAGE = 'Failed to create a cart';
export const CART_SHOPIFY_ERROR_MESSAGE = 'Shopify failed to create a cart';
export const CART_FIND_ERROR_MESSAGE = "Can't find cart";
export const ADD_PUP_SESSION_ERROR_MESSAGE = 'Session does not exist';
export const PET_FAILED_TO_ADD_ERROR_MESSAGE = 'Failed to add pet';
export const FIELD_IS_REQUIRED_ERROR_MESSAGE = 'Field is required';
