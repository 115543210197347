import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { InfoLink } from '../../account/InfoLink';
import {
  Container,
  ContentContainer,
  HeadingContainer,
  StyledIntro,
  InfoCard,
  InfoTitle,
  InfoSubtitle,
  InfoLine,
  InfoLabel,
  InfoListItem,
  InfoFooter,
  RecipeInfoContainer,
  StyledLink,
} from './styles';

export const FeedingGuidelinesInfoForm = ({
  title,
  petName,
  mealPlanName,
  dailyServing,
  calories,
  servingPerMeal,
  foodPerBox,
  frequency,
  hasFreshRecipes,
  hasBakedRecipes,
  freshRecipes,
  bakedRecipes,
}) => {
  const getFreshRecipes = () =>
    freshRecipes.map((recipe, index) => (
      <InfoListItem key={index}>{recipe}</InfoListItem>
    ));

  return (
    <Container>
      <StyledIntro>
        {petName}'s {mealPlanName} plan
      </StyledIntro>
      <HeadingContainer>
        <Heading
          headingLevel="h1"
          headingText={title}
          position="center"
          typography="heading1"
          horizontalPadding={2}
        />
      </HeadingContainer>
      <ContentContainer>
        <InfoLink
          title="📄 Product information"
          href={'/ingredients'}
          infoList={[
            'View information on ingredients, calorie content, pack sizes, and more.',
          ]}
          target="_blank"
        />

        <InfoCard>
          <InfoTitle>🗓️ Daily portion</InfoTitle>
          <InfoSubtitle>
            Feed {petName} {dailyServing} daily for a total of {calories}{' '}
            calories.
          </InfoSubtitle>
          <InfoLine>
            If your pup eats two meals daily, that's {servingPerMeal} food per
            meal.
          </InfoLine>
          <InfoLine>
            We've calculated just the right amount of calories per day based on{' '}
            {petName}'s age, weight, breed, and activity level to allow for
            precise portioning.
          </InfoLine>
          <InfoLine>
            Keep clean, fresh water out for your dog at all times.
          </InfoLine>
          {hasBakedRecipes && (
            <InfoLine>Made in a facility that handles peanuts.</InfoLine>
          )}
        </InfoCard>

        <InfoCard>
          <InfoTitle>🍗 {petName}'s recipes</InfoTitle>
          {hasFreshRecipes && (
            <RecipeInfoContainer>
              <InfoLabel>Fresh recipes</InfoLabel>
              {getFreshRecipes()}
            </RecipeInfoContainer>
          )}
          {hasBakedRecipes && (
            <RecipeInfoContainer>
              <InfoLabel>Baked recipes</InfoLabel>
              {bakedRecipes.map((recipe, index) => (
                <InfoListItem key={index}>Baked {recipe}</InfoListItem>
              ))}
            </RecipeInfoContainer>
          )}
          <InfoFooter>
            Your subscription box of {foodPerBox} will ship every {frequency}{' '}
            weeks.
          </InfoFooter>
        </InfoCard>

        <InfoCard>
          <InfoTitle>📞 Questions?</InfoTitle>
          <InfoLine>
            Our expert Canine Care team is here to help. Call or text us at{' '}
            {
              <StyledLink href={'tel:+18448865543'}>
                844-88-OLLIE (65543)
              </StyledLink>
            }{' '}
            or email us at{' '}
            {
              <StyledLink href={'mailto:press@myollie.com'}>
                caninecare@myollie.com
              </StyledLink>
            }{' '}
            for mealtime assistance.
          </InfoLine>
        </InfoCard>
      </ContentContainer>
    </Container>
  );
};

FeedingGuidelinesInfoForm.displayName = 'FeedingGuidelinesInfoForm';

FeedingGuidelinesInfoForm.propTypes = {
  title: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  mealPlanName: PropTypes.string.isRequired,
  dailyServing: PropTypes.string.isRequired,
  calories: PropTypes.string.isRequired,
  servingPerMeal: PropTypes.string.isRequired,
  foodPerBox: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  hasFreshRecipes: PropTypes.bool.isRequired,
  hasBakedRecipes: PropTypes.bool.isRequired,
  freshRecipes: PropTypes.array.isRequired,
  bakedRecipes: PropTypes.array.isRequired,
};
