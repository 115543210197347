import { useCallback } from 'react';
import { useAuthenticatedSWR } from '../api';
import { ADD_PUP_CART_BASE_URL } from './constants';

export const useAddPupPlanTypes = (cartId, petId) => {
  const { data, mutate, error, isValidating } = useAuthenticatedSWR(
    cartId && petId
      ? `${ADD_PUP_CART_BASE_URL}${cartId}/pets/${petId}/plans`
      : null,
  );

  const revalidate = useCallback(() => mutate(), [mutate]);

  return {
    isLoading: cartId && petId ? !data && !error : false,
    data,
    error,
    isValidating,
    revalidate,
  };
};
