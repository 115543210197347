'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { PupAvatar } from '@web/components';
import { Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import {
  userClicksReactivateMembership,
  userClicksViewFeedingGuide,
} from './events';
import {
  ButtonContainer,
  Container,
  Content,
  Text,
  GuidelinesButton,
  HeaderText,
  ReactivationButtonWrapper,
} from './styles.js';

export const YourPup = ({
  title,
  pupName,
  pupBreed,
  pupInfo,
  buttonLabel,
  reactivateButtonLabel,
  buttonUrlTarget,
  reactivateUrlTarget,
  pupBreedsNames,
  feedingGuidelinesHref,
  feedingGuidelinesLabel,
  inactiveMembershipText,
  hasActiveSubscription,
}) => {
  const reporter = useReporter();
  return (
    <>
      <Heading
        headingLevel="h3"
        typography={{ desktop: 'heading2', mobile: 'heading1' }}
        headingText={title}
        horizontalPadding={2}
        verticalPadding={1}
      />
      <Container>
        <PupAvatar breeds={pupBreedsNames} fullBleed={true} />
        <Content>
          <HeaderText>{pupName.toUpperCase()}</HeaderText>
          <Text typography="heading5">{pupBreed}</Text>
          <Text typography="body" color="text.secondary">
            {pupInfo}
          </Text>
          {hasActiveSubscription ? (
            feedingGuidelinesHref ? (
              <GuidelinesButton
                onClick={() => reporter.tag(userClicksViewFeedingGuide())}
                href={feedingGuidelinesHref}
              >
                {feedingGuidelinesLabel}
              </GuidelinesButton>
            ) : null
          ) : (
            <Text typography="body" color="hero.cranberry">
              {inactiveMembershipText}
            </Text>
          )}
        </Content>
        <ButtonContainer>
          {!hasActiveSubscription && (
            <ReactivationButtonWrapper>
              <LinkButton
                fullWidth
                variant="spinach"
                href={reactivateUrlTarget}
                onClick={() => reporter.tag(userClicksReactivateMembership())}
              >
                {reactivateButtonLabel}
              </LinkButton>
            </ReactivationButtonWrapper>
          )}
          <LinkButton fullWidth variant="secondary" href={buttonUrlTarget}>
            {buttonLabel}
          </LinkButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

YourPup.displayName = 'YourPup';
YourPup.propTypes = {
  hasActiveSubscription: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  pupName: PropTypes.string.isRequired,
  pupBreed: PropTypes.string.isRequired,
  pupBreedsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  pupInfo: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  reactivateButtonLabel: PropTypes.string.isRequired,
  buttonUrlTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  reactivateUrlTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  feedingGuidelinesHref: PropTypes.string,
  feedingGuidelinesLabel: PropTypes.string.isRequired,
  inactiveMembershipText: PropTypes.string.isRequired,
};
